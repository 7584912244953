<template>
  <Component
    :is="component?.type?.replace(/_/g, '-')"
    v-for="(component, index) in components"
    :key="index"
    :data="component"
    class="last:!mb-0"
  />
</template>

<script>
export default {
  props: {
    components: {
      type: Array,
      required: true,
    },
  },
}
</script>
